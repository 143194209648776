import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useCustomStyles = makeStyles()((theme) => ({
	list: {
		listStyle: 'none',
		marginBottom: theme.spacing.small,
		display: 'flex',
		gap: theme.spacing.xxsmall,
		flexDirection: 'column',
	},

	item: {
		position: 'relative',
		font: theme.typography.bodySm,
		wordBreak: 'break-word',
	},
	hasBullet: {
		paddingLeft: theme.spacing.small,

		'&:after': {
			display: 'inline-block',
			content: '""',
			width: '3px',
			height: '3px',
			position: 'absolute',
			borderRadius: '50%',
			left: theme.spacing.xxsmall,
			top: '50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: theme.palette.neutral[60],
		},
	},
}));
