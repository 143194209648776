import { FC } from 'react';

import { Document } from '@contentful/rich-text-types';
import { RichText } from '@vakantiesnl/components/src/molecules/RichText';
import { UnorderedListType } from '@vakantiesnl/types';
import classNames from 'classnames';

import { useCustomStyles } from './UnorderedList.style';

type Props = {
	containsRichText?: boolean;
	customClassName?: string;
	hasBullets?: boolean;
	items: UnorderedListType;
};

export const UnorderedList: FC<Props> = ({ containsRichText, customClassName, hasBullets = true, items }) => {
	const { classes } = useCustomStyles();

	return (
		<ul className={classNames(classes.list, customClassName)}>
			{items.map((item, key) => (
				<li className={classNames(classes.item, hasBullets && classes.hasBullet)} key={key}>
					{containsRichText && Array.isArray(item) && item?.length > 0
						? (item as Document[][])[0].map((itemContent, key) => (
								<RichText document={itemContent} key={key} />
							))
						: item}
				</li>
			))}
		</ul>
	);
};
